import { Auth } from '@aws-amplify/auth'
import type { MutationTree, ActionTree } from 'vuex'
import { type State, type UserState } from '@/parent/store/userStateTypes'
import { parentRepo } from '@/repos/parent'
/**
 * NOTE: `Parent` はデータ的には `Student` の中にあって同じ `username` を有するが `Cognito` は分けたいため,
 * `Parent` でサインインするときは `Prefix` をつける（`プレフィックス` + `Studentのusername` で `CognitoUser` がいる）.
 * refs: https://gitlab.com/mates-pay/app/new_reco/reco_for_parent/-/issues/122
 */
export const PREFIX = 'prt###'
export const state = (): UserState => ({
  __typename: 'Parent',
  id: -1,
  userId: -1,
  jukuId: -1,
  username: '',
  changedPasswordFlag: false,
  name: '',
  furigana: '',
  gender: 'male',
  studentId: -1
})

export const mutations: MutationTree<UserState> = {
  set(s, user) {
    Object.assign(s, { ...user })
  },
  reset(s) {
    Object.assign(s, state())
  }
}

export const actions: ActionTree<UserState, State> = {
  async signIn({ commit }, data) {
    await Auth.signIn(PREFIX + data.username, data.password)
    // getParentByUsernameはPrefixなしのusernameを使う!
    const user = await parentRepo.fetchSignedIn(data.username)
    commit('set', user)
  },
  async signOut({ commit, dispatch }) {
    await Auth.signOut()
    commit('reset')
    dispatch('jukuConfig/reset', undefined, { root: true })
    dispatch('targetParentEmailForUpdate/reset', undefined, { root: true })
  }
}
