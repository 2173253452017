
import { defineComponent, useMeta, computed, useContext } from '@nuxtjs/composition-api'
import { ApiErrorType } from '@/modules/error'

export default defineComponent({
  layout: 'plain',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const { i18n } = useContext()

    const errorState = computed(() => {
      if (props.error.message === ApiErrorType.MaintenanceMode) {
        return {
          type: 'MaintenanceMode',
          message: i18n.tc('maintenance')
        } as const
      }
      if (props.error.statusCode === 404) {
        return {
          type: 'NotFound',
          message: 'ご指定のページが見つかりませんでした。',
          title: '404'
        } as const
      }
      return {
        type: 'Others',
        message: 'エラーが発生しました。',
        title: 'ERROR!'
      } as const
    })

    const reload = () => {
      location.reload()
    }

    useMeta({ title: errorState.value.message })

    return {
      errorState,
      reload
    }
  },
  head: {}
})
